<template>
  <div
    v-if="ordersMedicines"
    class="card-company-table"
  >
    <b-table
      :items="ordersMedicines"
      responsive
      small
      :fields="fields"
      class="mb-0 text-center"
    >
      <!-- actions -->
      <template #cell(actions)="">
        <div class="d-flex align-items-center">
          <b-button
            v-b-tooltip.hover.top="'Asignar'"
            size="sm"
            variant="success"
            class="btn-icon mr-25"
            @click="modalAssigOrder"
          >
            <feather-icon icon="CheckCircleIcon" />
          </b-button>
        </div>
      </template>

    </b-table>
  </div>
</template>

<script>
import {
  BTable, BButton, VBModal, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    ordersMedicines: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'medicine', label: 'Medicamento' },
        { key: 'presentation', label: 'Presentación' },
        { key: 'units_requested', label: 'Unid. solicitadas' },
        { key: 'units_delivered', label: 'Unid. entregadas' },
        { key: 'date', label: 'Fecha' },
        { key: 'state', label: 'Estado' },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },
  methods: {
    modalAssigOrder() {
      this.$bvModal.show('modal-assign-order')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

</style>
