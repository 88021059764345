<template>
  <div
    v-if="assignedMedicines"
    class="card-company-table"
  >
    <b-table
      :items="assignedMedicines"
      responsive
      small
      :fields="fields"
      class="mb-0 text-center"
    >
      <!-- qr -->
      <template #cell(qr)="">
        <div class="cod_qr">
          <feather-icon
            icon="DownloadIcon"
            size="1.5x"
          />
          <div class="text text-center">
            QR
          </div>
        </div>
      </template>

      <!-- medicine -->
      <template #cell(medicine)="">
        <div class="d-flex align-items-center">
          <div class="">
            <div class="title mr-25">
              Ibuprofeno 500mg
            </div>
            <div class="subtitle">
              Tableta
            </div>
          </div>
          <div class="color_medicine" />
          <b-button
            v-b-tooltip.hover.top="'Observación'"
            size="sm"
            variant="secondary"
            class="btn-icon"
          >
            <feather-icon icon="MessageCircleIcon" />
          </b-button>
        </div>
      </template>
      <!-- state -->
      <template #cell(state)="data">
        <div v-if="data.item.state == 'Administrado'">
          <div
            class="status_bar accepted"
          />
          <div class="">
            {{ data.item.state }}
          </div>
        </div>
        <div v-else>
          <div
            class="status_bar rejected"
          />
          <div class="">
            {{ data.item.state }}
          </div>
        </div>
      </template>
      <!-- actions -->
      <template #cell(actions)="">
        <div class="d-flex align-items-center">
          <b-button
            v-b-tooltip.hover.top="'Devolver'"
            size="sm"
            variant="success"
            class="btn-icon mr-25"
          >
            <feather-icon icon="ExternalLinkIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Historial paciente'"
            size="sm"
            variant="secondary"
            class="btn-icon mr-25"
            @click="redirect('patientHistory')"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Orden emitida'"
            size="sm"
            variant="primary"
            class="btn-icon mr-25"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Eliminar'"
            size="sm"
            variant="warning"
            class="btn-icon mr-25"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BButton, VBModal, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    assignedMedicines: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'qr', label: '' },
        { key: 'medicine', label: 'Medicamentos/Concentración' },
        { key: 'assigned', label: 'Asignado' },
        { key: 'received_nurse', label: 'Recibido enfermera' },
        { key: 'returned_unadministered', label: 'Devuelto sin administrar' },
        { key: 'programmed', label: 'Programado' },
        { key: 'date_last_administered', label: 'Fecha de última administración' },
        { key: 'care_staff', label: 'Personal asistencial' },
        { key: 'state', label: 'Estado' },
        { key: 'actions', label: 'ACCIONES' },
      ],
    }
  },
  methods: {
    goFindPatient() {
      this.$router.push({ name: 'find-paciente' })
    },
    patientMedications() {
      this.$router.push({ name: 'patient-medication' })
    },
    redirect(name) {
      this.$router.push({ name: name })
    },
    modalExamen() {
      this.$bvModal.show('modal-assign-exam')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';

  .card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
      background: $theme-dark-body-bg !important;
    }
  }

  .text {
    color: #00CFE8;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    color: #636363;
  }

  .subtitle {
    font-size: 14px;
    color: #636363;
  }

  .cod_qr {
    cursor: pointer;
  }

  .color_medicine {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #00CFE8;
    margin-right: 10px;
  }

  .status_bar {
    padding: 4px 10px;
    border-radius: 50px;
  }

  .accepted {
    background-color: #7367F0;
  }

  .rejected {
    background-color: #EA5455;
  }

</style>
