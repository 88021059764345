<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-card
          no-body
          class="card__"
        >
          <template #header>
            <div class="title">
              <strong>Ordenes asignadas al paciente:</strong> Bryan Blanco - Habitación 201
            </div>
          </template>
          <orders-assigned-patient-table :orders-medicines="ordersMedicines" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="card__"
        >
          <template #header>
            <div class="title">
              <strong>Listado de medicamentos asignados al paciente</strong>
            </div>
          </template>
          <!-- <div class="demo-inline-spacing pl-2 mb-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
            >
              Ver historial despacho
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
            >
              Cod. qr consolidado
            </b-button>
          </div> -->
          <list-assigned-medicines-table :assigned-medicines="assignedMedicines" />
        </b-card>
      </b-col>
    </b-row>
    <modal-assigOrder />
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import OrdersAssignedPatientTable from './components/OrdersAssignedPatientTable'
import ListAssignedMedicinesTable from './components/ListAssignedMedicinesTable'
import ModalAssigOrder from './components/ModalAssigOrder'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    OrdersAssignedPatientTable,
    ListAssignedMedicinesTable,
    ModalAssigOrder,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ordersMedicines: [
        {
          medicine: 'Ibuprofeno 500mg',
          presentation: 'Tableta',
          units_requested: '10',
          units_delivered: '3',
          date: '30/11/2021',
          state: 'Pendiente',
        },
        {
          medicine: 'Dolex 500mg',
          presentation: 'Tableta',
          units_requested: '10',
          units_delivered: '3',
          date: '30/11/2021',
          state: 'Pendiente',
        },
      ],
      assignedMedicines: [
        {
          medicine: 'Acetaminofen',
          assigned: '3',
          received_nurse: '3',
          returned_unadministered: '1',
          programmed: '8 horas',
          date_last_administered: '30/11/2021 - 10:00',
          care_staff: 'Ana Martinez',
          state: 'Administrado',
        },
        {
          medicine: 'Acetaminofen',
          assigned: '3',
          received_nurse: '3',
          returned_unadministered: '1',
          programmed: '8 horas',
          date_last_administered: '30/11/2021 - 10:00',
          care_staff: 'Ana Martinez',
          state: 'No Administrado',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(100, 100, 100, 0.3),
      0 4px 15px 0 rgba(77, 76, 76, 0.15);
  }

  .card-header {
    background-color: #7367F0;
    padding: 8px;
  }

  .title {
    color: white;
    font-size: 18px;
  }
</style>
