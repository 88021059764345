<template>
  <b-modal
    id="modal-assign-order"
    centered
    title="Orden emitida"
  >
    <b-row class="mb-1">
      <b-col md="6">
        <div class="">
          <strong>Medicamento: </strong> {{ order.medicine }}
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Unidad de medida: </strong> {{ order.unit }}
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Presentación: </strong> {{ order.presentation }}
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Via de administración: </strong> {{ order.route_administration }}
        </div>
      </b-col>
      <b-col md="12">
        <div class="">
          <strong>Descripción: </strong> {{ order.description }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-checkbox disabled>
          A razon necesaria
        </b-form-checkbox>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-checkbox disabled>
          Dosis
        </b-form-checkbox>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Cada: </strong> 8 horas
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Período: </strong> 5 días
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Total orden: </strong> 10 tabletas
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Dosis: </strong>
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Fecha de inicio: </strong> 1/12/2021
        </div>
      </b-col>
      <b-col md="6">
        <div class="">
          <strong>Hora de inicio: </strong> 11:00 pm
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        class="mb-1"
      >
        <div class="title">
          Respuesta de farmacia
        </div>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Unidades entregadas"
          label-for="unit_delivered"
        >
          <b-form-input
            id="unit_delivered"
            v-model="formData.unit_delivered"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Pendientes"
          label-for="earrings"
        >
          <b-form-input
            id="earrings"
            v-model="formData.earrings"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Laboratorio"
          label-for="laboratory"
        >
          <b-form-input
            id="laboratory"
            v-model="formData.laboratory"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Lote"
          label-for="lot"
        >
          <b-form-input
            id="lot"
            v-model="formData.lot"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Fecha de vencimiento"
          label-for="expiration_date"
        >
          <b-form-input
            id="expiration_date"
            v-model="formData.expiration_date"
            type="date"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Observaciones"
          label-for="observation"
        >
          <b-form-textarea
            id="observation"
            v-model="formData.observation"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
      <b-button
        type="button"
        variant="primary"
        :disabled="isBusy || $v.$invalid"
        @click="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      order: {
        medicine: 'Ibuprofeno',
        unit: '500 mg',
        presentation: 'Tableta',
        route_administration: 'Oral',
        description: 'descrption',
      },
      formData: {
        unit_delivered: '',
        earrings: '',
        laboratory: '',
        lot: '',
        expiration_date: '',
        observation: '',
      },
      isBusy: false,
    }
  },
  validations: {
    formData: {
        unit_delivered: {
          required,
        },
        laboratory: {
          required,
        },
        lot: {
          required,
        },
        expiration_date: {
          required,
        },
        observation: {
          required,
        },
      },
  },
  methods: {
    saveData() {},
  },
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #636363;
  }
</style>
